.alert-signup {
    margin-top: 30px;
    text-align: center;
}

.alert.alert-instructions-sent {
    border: 1px solid #afbac5 !important;
    border-bottom: 3px solid #009999 !important;
    padding-left: 25px;
}

.alert svg {
    color: #009999;
    position: absolute;
    left: 5px;
}
