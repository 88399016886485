.modal-dialog {
    max-width: 960px!important;
    width: 100%;
    height: 80%;
    position: absolute;
    margin-top: 0!important;
    margin-bottom: 0!important;
    top: 10%;
    bottom: 10%;
    background: white;
}

.modal-content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 3;
}

.modal-body {
    display: block;
}

.modal-layout-aside,
.modal-layout-content {
    position: absolute;
    height: 100%;
    top: 0;
    padding: 1rem;
    display: block;
}

.modal-layout-aside {
    right: 0;
    width: percentage(1/4);
    border-left: 1px solid #e9ecef;
    z-index: 2;
}

.modal-layout-content {
    left: 0;
    padding-right: 1rem;
    width: percentage(3/4);
    overflow-y: auto;
    z-index: 1;

    .custom-icon-input {
        margin-bottom: 10px;
    }
}

.tag-modal-content {
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100% - 43px);
    z-index: 1;
    top: 43px;
    left: 0;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: auto;

    &-disabled {
        opacity: 0.1;
        pointer-events: none;
    }
}
