.btn.btn-primary {
    background-color: #e5173f;
    border-color: #e5173f;
}

.btn.btn-primary:hover {
    background-color: #ea2c51;
    border-color: #ea2c51;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #b71232!important;
    border-color: #b71232!important;
}

.btn.btn-linkedin {
    background-color: transparent;
    color: #0073b1;
    border: 1px solid #0073b1;
    display: block;
    width: 100%;
}

.btn.btn-linkedin:hover {
    background-color: #0073b1;
    color: white;
}

.btn.btn-google {
    background-color: transparent;
    color: #d54f50;
    border: 1px solid #d54f50;
    display: block;
    width: 100%;
}

.btn.btn-google:hover {
    background-color: #d54f50;
    color: white;
}

.btn.btn-new-account {
    background-color: #f7f8fa;
    border: 1px solid #d8dde6;
    display: block;
    width: 100%;
}

.btn.btn-new-account:hover {
    background-color: white;
}

.btn.btn-different-account {
    background-color: white;
    border: 1px solid #e5173f;
    color: #e5173f;
    display: block;
    width: 100%;
}

.btn.btn-different-account:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
    text-decoration: none;
}

.tag-button-wrapper {
    &.tag-button-block {
        margin-right: 10px;
        tag-button {
            width: 100%;
        }
    }
}

.panel-header-button {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 0;
    justify-content: flex-end;

}
