@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import "./css/buttons";
@import "./css/authentication";
@import "./css/card";
@import "./css/inputs";
@import "./css/alerts";
@import "./css/scaffolding";
@import "./css/modals";
@import "./css/panel";
@import "./css/pills";
@import "./css/tabs";
@import "./css/wizard";
@import "./css/headerlessTable.scss";
@import './Components/SectionHeader/SectionHeader.css';

body {
    margin: 0;
    padding: 0;
    background-color: #FAFBFC !important;
    overflow-y: overlay !important;
    scrollbar-width: thin !important;
    scrollbar-color: #099 #f1f1f1;
    scrollbar-base-color: #099; // Supported for IE only
    scrollbar-arrow-color: #099; // Supported for IE only
    -ms-overflow-style: -ms-autohiding-scrollbar;

    color: #4b4e52!important;
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'!important;
    font-size: 13px!important;
    font-weight: 400;
}
.tag-table{
    overflow-y: overlay !important;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
    background: rgba(0, 153, 153, 1);
}
:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 153, 153, 1);
}
.scrolling::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
    background: rgba(0, 153, 153, 1);
}
::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 153, 153, 1);
}

// add an IE11 only style for the Labels inside a tag-toggle
// otherwise we can have incorrect styles picked when we don't have shadow dom.
_:-ms-fullscreen, .tag-toggle {
    & label {
        display: inline;
        line-height: 1.15;
    }
}

// Stop intercom from forcing it's way over the top of the toast alerts
.intercom-lightweight-app, .intercom-app, .intercom-app div:has(iframe) {
    z-index: 199999 !important;
}
.col,
[class*="col-"] {
    position: relative;
}


