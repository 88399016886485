.custom-icon-input {
    position: relative;

    input {
        padding-left: 32px;
    }

    input[readonly] {
        background-color: white;
        border: none;
    }

    svg, tag-icon {
        position: absolute;
    }

    svg {
        top: 9px;
        left: 11px;
        color: #afbac5;
    }

    tag-icon {
        top: 4px;
        left: 6px;
        color: #afbac5;
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #53b8b4!important;
}
