.wizard-actions {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
}

.wizard-step {
  background-color: white;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  text-align: left;
  position: relative;
  display: block;
}