.authentication-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: url("../resources/bg.jpg") no-repeat 400px top fixed white;
    background-size: cover;

    h4 {
        margin-top: 42px;
        margin-bottom: 15px;
        font-weight: 300;
    }

    h5 {
        margin-top: 30px;
        margin-bottom: 20px;
        font-weight: 300;
    }

    label {
        margin-top: 15px;
        margin-bottom: 5px;
    }
}

.authentication-view .custom-checkbox {
    margin-top: 16px;
    margin-bottom: 16px;
}

.authentication-view .custom-checkbox .float-right {
    margin-top: 16px;
    color: #e5173f;
}

.authentication-view .custom-checkbox .float-right a {
    color: #e5173f;
}

.recaptcha {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

#g-recaptcha {
    display: inline-block;
}

.authentication-action-btn {
    margin-top: 20px;
    position: relative;
}

.authentication-action-btn a:hover {
    text-decoration: none;
}
