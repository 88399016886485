.card {
  border-radius: 0 !important;
  border-top: 2px solid #009999 !important;
  &.no-border {
    border-top: 0 !important;
  }

  &.zero-border {
    border: 0 !important;
  }

  .card-header {
    border-radius: 0 !important;
    background: #f9f9f9 !important;

    &.-overlap {
      position: absolute;
      z-index: 101;
      width: 100%;
    }

    &.-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .card-table.right {
    width: 100%;
  }
}


.card-table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    font-weight: normal;
    padding: 5px;
  }
}

.card-table, .card-table th, .card-table td {
  border: 1px solid #e2ecef;
}

.no-data-card {
  border: 1px solid #e2ecef;
  background: white;
  padding: 1rem;

  .card-body {
    padding: 1rem 0;
    font-size: 1.5rem;
    text-align: center;
    color: gray;
  }

  .approvedby {
    margin-top: 1rem;
    text-align: left;
    color: darkslategray;
  }
}

.no-visits{
  margin-top: 4.5rem;
  font-size: 2rem;
  text-align: center;
  color: gray;
}

.no-parts{
  font-size: 2rem;
  text-align: center;
  color: gray;
}

.action-panel-body {
  padding: 1rem;
  overflow-y: auto;
  display: block;
  position: relative;
  max-height: 150px;

  .action-panel-item {
    padding: 1rem;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-bottom: 1rem;
    background: #e6f5f5;
  }
}

.action-panel tag-button {
  display: inline-block;
}

.action-panel-footer {
  background: white;
  border-top: 0;
}
