.panel {
  display: block;
  position: relative;
}

.panel .panel-body {
  display: block;
  position: relative;
  border: 1px solid lightgrey;
  padding: 20px;
}

.panel .panel-header {
  display: block;
  position: relative;
  border: 1px solid lightgrey;
  border-top: 2px solid #53B8B4;
  padding: 20px;
}

.panel .panel-header + .panel .panel-body {
  border-top: 0;
}

.panel + .panel {
  margin-top: 20px;
}

.order-summary-panel {
  margin-top: 35px;
  border-top: 2px solid #53B8B4;
}

.order-summary-panel-no-colour {
    margin-top: 35px;
    border-top: 2px;
  }
  .order-summary-panel-sunflower {
    margin-top: 35px;
    border-top: 2px solid #e8ad44;
  }
  .order-summary-panel-tango {
    margin-top: 35px;
    border-top: 2px solid #d5763f;
  }
  .order-summary-panel-project {
    margin-top: 35px;
    border-top: 2px solid #712e58;
  }
  .order-summary-panel-inhouse {
    margin-top: 35px;
    border-top: 2px solid #eb6f7b;
  }
  .order-summary-panel-contractors{
    margin-top: 35px;
    border-top: 2px solid #036d83;
  }
  .order-summary-panel-supply{
    margin-top: 35px;
    border-top: 2px solid #555b70;
  }
  .order-summary-panel-insurance{
    margin-top: 35px;
    border-top: 2px solid #036d83;
  }
  .order-summary-panel-notification{
    margin-top: 35px;
    border-top: 2px solid #c33f3b
  }