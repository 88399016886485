.toggled-pills {
  display: inline-flex;
  position: relative;

  .pill {
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid transparent;
    border-radius: 15px;

    &.active {
      border-color: #009999;
      color: #009999;
    }
  }
}